exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-martingale-js": () => import("./../../../src/pages/martingale.js" /* webpackChunkName: "component---src-pages-martingale-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-strategies-js": () => import("./../../../src/pages/strategies.js" /* webpackChunkName: "component---src-pages-strategies-js" */),
  "component---src-templates-strategy-js": () => import("./../../../src/templates/strategy.js" /* webpackChunkName: "component---src-templates-strategy-js" */)
}

